

// resources/assets/js/app.js

import $ from 'jquery';
window.$ = window.jQuery = $;

import 'jquery-ui/ui/widgets/datepicker.js';

require('bootstrap');

require('cropperjs');

window.Swal = require('sweetalert2');
//Bootstrap Popover
$(document).ready(function() {
    $('[data-toggle="popover"]').popover();   
});

$(document).ajaxComplete(function() {
    $('[data-toggle="popover"]').popover();
});

import Echo from 'laravel-echo';

window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//   broadcaster: 'pusher',
//   key: process.env.MIX_PUSHER_APP_KEY,
//   cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//   forceTLS: false,
//   wsHost: window.location.hostname,
//   wsPort: 6001,
// });

// For ssl connections use this
window.Echo = new Echo({
  broadcaster: 'pusher',
  key: process.env.MIX_PUSHER_APP_KEY,
  cluster: process.env.MIX_PUSHER_APP_CLUSTER,
  httpHost: 'ws.schedy.sk', // Your domain
  httpsHost: 'ws.schedy.sk', // Your domain
  wsHost: 'ws.schedy.sk', // Your domain
  wssHost: 'ws.schedy.sk', // Your domain
  encrypted: true,
  wsPort: 80, // Https port
  wssPort: 443, // Https port
  disableStats: true, // Change this to your liking this disables statistics
  forceTLS: true,
  enabledTransports: ['ws', 'wss'],
  disabledTransports: ['sockjs', 'xhr_polling', 'xhr_streaming'] // Can be removed
});


/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i);
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default));

// Vue.component('example-component', require('./components/ExampleComponent.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

// const app = new Vue({
//     el: '#app',
// });


//NAVIGATION SCRIPT
// $(document).ready(function() {
//   $sidebar = $('.sidebar');
// });

// let mobile_menu_visible = 0;
// let mobile_menu_initialized = false;

// $(document).on('click', '.navbar-toggler', function() {
//   $toggle = $(this);

//   if (mobile_menu_visible == 1) {
//     $('html').removeClass('nav-open');

//     $('.close-layer').remove();
//     setTimeout(function() {
//       $toggle.removeClass('toggled');
//     }, 400);

//     mobile_menu_visible = 0;
//   } else {
//     setTimeout(function() {
//       $toggle.addClass('toggled');
//     }, 430);

//     var $layer = $('<div class="close-layer"></div>');

//     if ($('body').find('.main-panel').length != 0) {
//       $layer.appendTo(".main-panel");

//     } else if (($('body').hasClass('off-canvas-sidebar'))) {
//       $layer.appendTo(".wrapper-full-page");
//     }

//     setTimeout(function() {
//       $layer.addClass('visible');
//     }, 100);

//     $layer.click(function() {
//       $('html').removeClass('nav-open');
//       mobile_menu_visible = 0;

//       $layer.removeClass('visible');

//       setTimeout(function() {
//         $layer.remove();
//         $toggle.removeClass('toggled');

//       }, 400);
//     });

//     $('html').addClass('nav-open');
//     mobile_menu_visible = 1;

//   }

// });

// // activate collapse right menu when the windows is resized
// $(window).resize(function() {
//   md.initSidebarsCheck();
// });

// md = {
//   initSidebarsCheck: function() {
//     if ($(window).width() <= 991) {
//       if ($sidebar.length != 0) {
//         md.initRightMenu();
//       }
//     }
//   },

//   initRightMenu: debounce(function() {
//     $sidebar_wrapper = $('.sidebar-wrapper');

//     if (!mobile_menu_initialized) {
//       $navbar = $('nav').find('.navbar-collapse').children('.navbar-nav');

//       mobile_menu_content = '';

//       nav_content = $navbar.html();

//       nav_content = '<ul class="nav navbar-nav nav-mobile-menu">' + nav_content + '</ul>';

//       navbar_form = $('nav').find('.navbar-form').get(0).outerHTML;

//       $sidebar_nav = $sidebar_wrapper.find(' > .nav');

//       // insert the navbar form before the sidebar list
//       $nav_content = $(nav_content);
//       $navbar_form = $(navbar_form);
//       $nav_content.insertBefore($sidebar_nav);
//       $navbar_form.insertBefore($nav_content);

//       $(".sidebar-wrapper .dropdown .dropdown-menu > li > a").click(function(event) {
//         event.stopPropagation();

//       });

//       // simulate resize so all the charts/maps will be redrawn
//       window.dispatchEvent(new Event('resize'));

//       mobile_menu_initialized = true;
//     } else {
//       if ($(window).width() > 991) {
//         // reset all the additions that we made for the sidebar wrapper only if the screen is bigger than 991px
//         $sidebar_wrapper.find('.navbar-form').remove();
//         $sidebar_wrapper.find('.nav-mobile-menu').remove();

//         mobile_menu_initialized = false;
//       }
//     }
//   }, 200),

// }

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.

// function debounce(func, wait, immediate) {
//   var timeout;
//   return function() {
//     var context = this,
//       args = arguments;
//     clearTimeout(timeout);
//     timeout = setTimeout(function() {
//       timeout = null;
//       if (!immediate) func.apply(context, args);
//     }, wait);
//     if (immediate && !timeout) func.apply(context, args);
//   };
// };

//NAVIGATION 
var mobile_menu_visible = 0;

    $(document).on('click', '.navbar-toggler', function() {
      let toggle = $(this);

    if (mobile_menu_visible == 1) {
      $('html').removeClass('nav-open');

      $('.close-layer').remove();
      setTimeout(function() {
        toggle.removeClass('toggled');
      }, 400);

      mobile_menu_visible = 0;
    } else {
      setTimeout(function() {
        toggle.addClass('toggled');
      }, 430);

      var $layer = $('<div class="close-layer"></div>');

      if ($('body').find('.main-panel').length != 0) {
        $layer.appendTo(".main-panel");

      } else if (($('body').hasClass('off-canvas-sidebar'))) {
        $layer.appendTo(".wrapper-full-page");
      }

      setTimeout(function() {
        $layer.addClass('visible');
      }, 100);

      $layer.click(function() {
        $('html').removeClass('nav-open');
        mobile_menu_visible = 0;

        $layer.removeClass('visible');

        setTimeout(function() {
          $layer.remove();
          toggle.removeClass('toggled');

        }, 400);
      });

      $('html').addClass('nav-open');
      mobile_menu_visible = 1;

    }
});


